export const ACTION_TYPE = {
  VIEW: 1,
  CLICK: 2,
};

export const JOURNEY_ITEM_TYPE = {
  MAIN_BANNER: 1,
  SUB_BANNER: 2,
  POPUP: 3,
};

export default {
  ACTION_TYPE,
  JOURNEY_ITEM_TYPE,
};
